import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';
import "@/assets/css/reset_pc.scss"
import "@/assets/css/animate.min.css"
import ajax from "@/assets/ajax"

Vue.config.productionTip = false

Vue.use(ElementUI);
ajax.store = store

Vue.prototype._ajax = ajax
Vue.prototype.routerPush = function(name, data) {
    if (!name) {
        return
    }
    this.$router.push({
        name: name,
        query: data
    });
}
Vue.prototype.openFun = function(url) {
    if (!url) {
        return
    }
    window.open(url)
}

var app = new Vue({
    router,
    store,
    render: h => h(App),
    beforeCreate() {
        Vue.prototype.$bus = this //安装全局事件总线，this就是当前应用的vm
    },
})


app.$mount('#app')
