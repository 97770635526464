var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ManMachine"},[_c('div',{staticClass:"img-box",style:({
        width: _vm.config.width+'px',
        height: _vm.config.height+'px',
    })},[_c('img',{attrs:{"src":_vm.img,"alt":""}}),_c('div',{staticClass:"target",style:({
            top: _vm.target.top+'px',
            left: _vm.target.left+'px',
        })}),_c('div',{staticClass:"cover-target",style:({
            top: _vm.target.top+'px',
            left: _vm.move_left+'px',
            backgroundImage: 'url('+_vm.img+')',
            backgroundPosition: (_vm.target.left*-1)+'px '+(_vm.target.top*-1)+'px',
            backgroundSize: _vm.config.width+'px '+_vm.config.height+'px'
        })}),_c('div',{staticClass:"cover-target-shadow",style:({
            top: _vm.target.top+'px',
            left: _vm.move_left+'px',
        })})]),_c('div',{staticClass:"bar",class:{
        act:_vm.test
    },on:{"mousemove":_vm.mousemove}},[_c('div',{staticClass:"cover-bar",style:({
            width: _vm.move_left+4+'px',
        })}),_c('div',{staticClass:"text"},[_vm._v("拖动滑块完成拼图")]),_c('div',{staticClass:"bar-item _link",style:({
            left:_vm.move_left+'px'
        }),on:{"mousedown":_vm.mousedown,"mouseup":_vm.mouseup}},[_vm._v(" | | | ")])])])
}
var staticRenderFns = []

export { render, staticRenderFns }