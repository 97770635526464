<template>
    <div>
        <div class="login animate__animated animate__fadeIn">
            <div class="box">
                <div class="l">
                    <img src="@/assets/img/login.png" alt="">
                </div>
                <div class="r">
                    <!-- <img src="@/assets/img/logo.png" alt="" class="logo"> -->
                    <div class="tab">
                        <div class="item _link" :class="{
                        act:type=='login'
                    }" @click="typeFun('login')">密码登录</div>
                        <div class="item _link" :class="{
                        act:type=='register'
                    }" @click="typeFun('register')">注册</div>
                    </div>
                    <el-form ref="form" :rules="rules" :model="form" size="small">
                        <el-form-item prop="account">
                            <el-input v-model="form.account" placeholder="请输入手机号"></el-input>
                        </el-form-item>
                        <el-form-item prop="password">
                            <el-input v-model="form.password" type="password" placeholder="请输入密码"></el-input>
                        </el-form-item>
                        <template v-if="type=='register'||type=='repass'">
                            <el-form-item prop="passwordAgain">
                                <el-input type="password" v-model="form.passwordAgain" placeholder="请输入确认密码"></el-input>
                            </el-form-item>
                            <el-form-item prop="code">
                                <el-input placeholder="请输入验证码" v-model="form.code" class="input-with-select">
                                    <el-button slot="append" :disabled="!!djs" @click="ManMachineShow">
                                        {{djs?"重发("+djs+")":"发送验证码"}}
                                    </el-button>
                                </el-input>
                            </el-form-item>
                        </template>
                        <el-form-item v-if="type!='repass'">
                            <el-checkbox v-model="form.checked">
                                我已阅读并同意
                                <el-button type="text" @click="protocol_show=true">《用户协议》</el-button>
                                <el-button type="text" @click="protocol2_show=true">《隐私协议》</el-button>
                            </el-checkbox>
                        </el-form-item>
                        <el-form-item>
                            <el-button class="sub" type="primary" @click="loginSub">{{ { login:"登录", register:"立即创建", repass:"提交", }[type] }}
                            </el-button>
                        </el-form-item>
                        <el-form-item v-if="type!='repass'" style="text-align:center">
                            <el-button type="text" @click="typeFun('repass')">忘记密码？</el-button>
                        </el-form-item>
                    </el-form>
                </div>
                <div class="close _link" @click="$emit('close')">
                    <i class="el-icon-circle-close"></i>
                </div>
            </div>
        </div>
        <el-dialog title="人机校验" :visible.sync="dialogVisible" width="388px">
            <ManMachine ref="ManMachine" @confirm="registerSms"></ManMachine>
        </el-dialog>
        <el-dialog :title="protocol.title" :visible.sync="protocol_show" :close-on-click-modal="false" :close-on-press-escape="false">
            <div style="padding:0 15px;" v-html="protocol.detail">
            </div>
        </el-dialog>
        <el-dialog :title="protocol2.title" :visible.sync="protocol2_show" :close-on-click-modal="false" :close-on-press-escape="false">
            <div style="padding:0 15px;" v-html="protocol2.detail">
            </div>
        </el-dialog>
    </div>
</template>
<script>
import ManMachine from '@/components/ManMachine.vue'
export default {
    components: {
        ManMachine
    },
    data() {
        var passReg = /^[a-zA-Z0-9]{6,20}$/
        return {
            type: "login",
            url_obj: {
                login: {
                    sub: "login",
                },
                register: {
                    sub: "register",
                    sms: "registerSms",
                },
                repass: {
                    sub: "updatePassword",
                    sms: "updatePasswordSms",
                },
            },
            form: {  
                /*account: 17640692480,
                password: "123qweasd",*/
            },
            rules: {
                account: [
                    //
                    {
                        required: true,
                        message: '请输入账号',
                        trigger: 'blur'
                    },
                    {
                        validator: (rule, value, callback) => {
                            var k = /^1\d{10}$/
                            console.log(k, value, k.test(value))
                            if (!value) {
                                callback(new Error('请输入账号'));
                                return
                            } else if (!k.test(value)) {
                                callback(new Error('请输入正确的手机号码'));
                                return
                            }
                            callback();
                        },
                        trigger: 'blur'
                    },
                ],
                password: [
                    //
                    {
                        required: true,
                        message: '请输入密码',
                        trigger: 'blur'
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (!value) {
                                callback(new Error('请输入密码'));
                                return
                            } else if (!passReg.test(value)) {
                                callback(new Error('请输入6-20位数字之符'));
                                return
                            } else if (value && this.form.passwordAgain) {
                                this.$refs.form.validateField('passwordAgain');
                            }
                            callback();
                        },
                        trigger: 'blur'
                    },
                ],
                passwordAgain: [
                    //
                    {
                        required: true,
                        message: '请输入确认密码',
                        trigger: 'change'
                    },
                    {
                        validator: (rule, value, callback) => {
                            if (value !== this.form.password) {
                                callback(new Error('两次输入密码不一致!'));
                            } else {
                                callback();
                            }
                        },
                        trigger: 'blur'
                    },
                ],
                code: [
                    //
                    {
                        required: true,
                        message: '请输入验证码',
                        trigger: 'change'
                    },
                ],
            },
            djs: 0,
            dsq: null,
            dialogVisible: false,
            protocol_show: false,
            protocol: {},
            protocol2_show: false,
            protocol2: {},
        }
    },
    mounted() {
        this._ajax.post({
            url: "Protocol",
            data: {
                id: 1
            },
            success: (res) => {
                this.protocol = res.data
            }
        })
        this._ajax.post({
            url: "Protocol",
            data: {
                id: 3
            },
            success: (res) => {
                this.protocol2 = res.data
            }
        })
    },
    methods: {
        loginSub() {
            if (!this.form.checked && this.type != "repass") {
                this.protocol_show = true
                this.protocol2_show = true
                this.form.checked = true
                return
            }
            if (!this.form.registerSms && this.type != "login") {
                this.ManMachineShow()
                return
            }
            this.$refs.form.validate((valid) => {
                if (valid) {
                    this._ajax.post({
                        url: this.url_obj[this.type].sub,
                        data: this.form,
                        success: (res) => {
                            if (this.type == "repass") {
                                this.$store.commit("tokenSet", "")
                                this.type = "login"
                                this.form = {}
                                this.$notify({
                                    title: '重制密码成功',
                                })
                                return
                            }
                            this.$store.commit("tokenSet", res.data.token)
                            history.go(0)
                        }
                    })
                }
            });
        },
        ManMachineShow() {
            this.$refs.form.validateField("account", (valid) => {
                if (!valid) {
                    this.dialogVisible = true
                    this.$nextTick(() => {
                        this.$refs.ManMachine.initFun()
                    })
                } else {
                    console.log('error submit!!');
                    return false;
                }
            });
        },
        registerSms() {
            this.djs = 60
            this.dialogVisible = false
            this._ajax.post({
                url: this.url_obj[this.type].sms,
                data: this.form,
                success: (res) => {
                    this.form.registerSms = true
                    this.dsq = setInterval(() => {
                        this.djs--;
                        if (this.djs <= 0) {
                            clearInterval(this.dsq)
                            this.djs = 0
                        }
                    }, 1000)
                }
            })
        },
        typeFun(type) {
            clearInterval(this.dsq)
            this.djs = 0
            this.dialogVisible = false
            this.form.registerSms = false
            this.type = type
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/var.scss";
.login {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgba(#000, .5);
    z-index: 13;
    .box {
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        width: 1120px;
        height: 560px;
        margin: auto;
        display: flex;
        flex-direction: row;
        .r {
            background: #fff;
            border-radius: 0 20px 20px 0;
            flex-grow: 1;
            padding: 0 130px;
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;
            .title {
                margin-top: 12px;
                font-size: 18px;
                line-height: 26px;
                color: #666666;
            }
            .tab {
                display: flex;
                flex-direction: row;
                width: 100%;
                margin-top: 32px;
                .item {
                    width: 50%;
                    text-align: center;
                    font-size: 18px;
                    line-height: 26px;
                    color: #C9C9C9;
                    -webkit-transition: all .3s ease-in-out;
                    -moz-transition: all .3s ease-in-out;
                    -ms-transition: all .3s ease-in-out;
                    -o-transition: all .3s ease-in-out;
                    transition: all .3s ease-in-out;
                    position: relative;
                    &:after {
                        content: "";
                        position: absolute;
                        right: 0;
                        left: 0;
                        margin: auto;
                        display: block;
                        width: 0;
                        height: 4px;
                        background: rgba($main_color, .5);
                        border-radius: 2px;
                        -webkit-transition: all .3s ease-in-out;
                        -moz-transition: all .3s ease-in-out;
                        -ms-transition: all .3s ease-in-out;
                        -o-transition: all .3s ease-in-out;
                        transition: all .3s ease-in-out;
                        z-index: 1;
                    }
                    &:hover,
                    &.act {
                        color: #000;
                        &:after {
                            width: 50px;
                        }
                    }
                }
            }
            .el-form {
                width: 100%;
                margin-top: 24px;
                .sub {
                    width: 100%;
                }
            }
        }
        .close {
            padding: 10px;
            position: absolute;
            left: 100%;
            bottom: 100%;
            font-size: 24px;
            color: #fff;
        }
    }
}
</style>