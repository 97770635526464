<template>
    <div class="ManMachine">
        <div class="img-box" :style="{
            width: config.width+'px',
            height: config.height+'px',
        }">
            <img :src="img" alt="">
            <div class="target" :style="{
                top: target.top+'px',
                left: target.left+'px',
            }"></div>
            <div class="cover-target" :style="{
                top: target.top+'px',
                left: move_left+'px',
                backgroundImage: 'url('+img+')',
                backgroundPosition: (target.left*-1)+'px '+(target.top*-1)+'px',
                backgroundSize: config.width+'px '+config.height+'px'
            }"></div>
            <div class="cover-target-shadow" :style="{
                top: target.top+'px',
                left: move_left+'px',
            }"></div>
        </div>
        <div class="bar" :class="{
            act:test
        }" @mousemove="mousemove">
            <div class="cover-bar" :style="{
                width: move_left+4+'px',
            }"></div>
            <div class="text">拖动滑块完成拼图</div>
            <div class="bar-item _link" :style="{
                left:move_left+'px'
            }" @mousedown="mousedown" @mouseup="mouseup">
                | | |
            </div>
        </div>
    </div>
</template>
<script>
import ManMachine from '@/assets/ManMachine.png'
export default {
    components: {},
    computed: {},
    data() {
        return {
            config: {
                height: 200,
                width: 348
            },
            test: true,
            img: ManMachine,
            target: {
                top: 50,
                left: 150
            },
            move_data: {},
            down_data: {},
            move_left: 0
        }
    },
    mounted() {
        this.initFun()
    },
    methods: {
        initFun() {
            this.test = false
            this.move_left = 0
            var top = .33 + Math.random() * .33
            this.target.top = Math.ceil(top * this.config.height)
            var left = .50 + Math.random() * .25
            this.target.left = Math.ceil(left * this.config.width)
        },
        mousemove(e) {
            if (this.test) {
                return
            }
            this.move_data.X = e.screenX
            if (!this.down_data.X) {
                this.move_left = 0
                return
            }
            var move_left = this.move_data.X - this.down_data.X
            if (move_left > this.config.width) {
                move_left = this.config.width
            }
            if (move_left < 0) {
                move_left = 0
            }
            this.move_left = move_left
        },
        mousedown(e) {
            if (this.test) {
                return
            }
            this.down_data.X = e.screenX
        },
        mouseup() {
            this.down_data.X = null
            if (this.target.left - 5 < this.move_left && this.move_left < this.target.left + 5) {
                this.test = true
                this.move_left = this.target.left
                this.$emit("confirm")
                return
            }
            this.move_left = 0
        }
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/var.scss";
.ManMachine {
    width: 348px;
    user-select: none;
    .img-box {
        position: relative;
        img {
            height: 100%;
            width: 100%;
            border-radius: 4px;
        }
        .target {
            position: absolute;
            width: 56px;
            height: 56px;
            background: rgba(#fff, .7);
            mask-image: url(@/assets/img/ManMachine_mask.png);
            background-image: url(@/assets/img/ManMachine_shadow-i.png);
            border-radius: 4px;
        }
        .cover-target {
            position: absolute;
            height: 56px;
            width: 56px;
            top: 0;
            left: 0;
            mask-image: url(@/assets/img/ManMachine_mask.png);
        }
        .cover-target-shadow {
            position: absolute;
            height: 68px;
            width: 68px;
            background-image: url(@/assets/img/ManMachine_shadow.png);
            transform: translate(-6px, -6px);
        }
    }
    .bar {
        height: 56px;
        line-height: 56px;
        text-align: center;
        position: relative;
        margin-top: 20px;
        border-radius: 4px;
        background: #EDF1F8;
        .bar-item {
            height: 56px;
            width: 56px;
            box-sizing: border-box;
            position: absolute;
            left: 0px;
            top: 0;
            box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.5);
            background: #fff;
            border-radius: 4px;
            color: #6AA0FF;
        }
        .text {
            position: relative;
            color: #B9C0D6;
        }
        .cover-bar {
            width: 300px;
            height: 56px;
            background: #6AA0FF;
            position: absolute;
            left: 0;
            top: 0;
            border-radius: 4px;
        }
        &.act {
            .bar-item {
                color: #24D100;
            }
            .text {
                color: #fff;
            }
            .cover-bar {
                background: #24D100;
            }
        }
    }
}
</style>