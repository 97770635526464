<template>
    <div id="app">
        <Nav></Nav>
        <router-view/>
        <Footer></Footer>
        <Login v-show="Login_show" ref="Login" @close="Login_show=false"></Login>
    </div>
</template>
<script>
import Nav from '@/components/Nav.vue'
import Footer from '@/components/Footer.vue'
import Login from '@/components/Login.vue'

export default {
    components: {
        Nav,
        Footer,
        Login,
    },
    data() {
        return {
            Login_show: false,
        }
    },
    mounted() {
        this.$bus.$off("LoginFun")
        this.$bus.$on("LoginFun", (e) => {
            this.$refs.Login.type = e || "login"
            this.Login_show = true
        })
        if (this.$store.state.token) {
            this.UserInfoGet()
        }
    },
    methods: {
        UserInfoGet() {
            this._ajax.post({
                url: "getUserInfo",
                success: (res) => {
                    this.$store.commit("userSet", res.data)
                }
            })
        },
    }
}
</script>
<style scoped lang="scss">
</style>