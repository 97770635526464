<template>
    <div>
        <div class="foot-line" v-if="!$store.state.token">
            <div class="_wrap">
                <div class="l">想了解更多关于我们的信息</div>
                <div class="r">
                    <div class="btn _link" @click="register">注册</div>
                    <div class="btn _link" @click="login">登录</div>
                </div>
            </div>
        </div>
        <div class="footer">
            <div class="_wrap">
                <div class="item">
                    <div class="title">
                        <span class="text">网站导航</span>
                        <span class="text2">Navigation</span>
                    </div>
                    <div class="nav">
                        <div class="n-item _link" @click="routerPush('Index')">
                            首页
                        </div>
                        <div class="n-item _link" @click="routerPush('Programme')">
                            解决方案
                        </div>
                        <div class="n-item _link" @click="routerPush('Information')">
                            资讯
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="title">
                        <span class="text">联络方式</span>
                        <span class="text2">Contact</span>
                    </div>
                    <div class="contact">
                        <div class="c-item">
                            <span>客服电话</span>{{data.phone}}
                        </div>
                        <div class="c-item">
                            <span>公司邮箱</span>{{data.email}}
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="title">
                        <span class="text">关注我们</span>
                        <span class="text2">Follow</span>
                    </div>
                    <div class="code">
                        <div class="cd-item">
                            <div class="name">商务微信</div>
                            <img src="@/assets/code/w.png" alt="">
                        </div>
                        <div class="cd-item">
                            <div class="name">抖音号</div>
                            <img src="@/assets/code/d.png" alt="">
                        </div>
                        <div class="cd-item">
                            <div class="name">Bilibili号</div>
                            <img src="@/assets/code/b.png" alt="">
                        </div>
                        <div class="cd-item">
                            <div class="name">微信视频号</div>
                            <img src="@/assets/code/s.png" alt="">
                        </div>
                    </div>
                </div>
                <div class="item">
                    <div class="title">
                        <img src="@/assets/img/logo-b.png" alt="">
                    </div>
                    <div class="contact">
                        <div class="c-item">
                            <span class="_link" @click="protocol_show=true">隐私条例</span>
                        </div>
                        <div class="c-item">
                            <span class="_link" @click="openFun('https://beian.miit.gov.cn/')">Copyright © Hanhan D 粤ICP备2023070934号</span>
                        </div>
                    </div>
                </div>
            </div>
            <el-drawer title="隐私政策" :visible.sync="protocol_show" direction="rtl">
                <div style="padding:0 15px;color:#000" v-html="protocol.detail">
                </div>
            </el-drawer>
        </div>
    </div>
</template>
<script>
export default {
    components: {},
    data() {
        return {
            data: {},
            protocol_show: false,
            protocol: {},
        }
    },
    mounted() {
        this._ajax.post({
            url: "ContactUs",
            success: (res) => {
                this.data = res.data
            }
        })
        this._ajax.post({
            url: "Protocol",
            data: {
                id: "1"
            },
            success: (res) => {
                this.protocol = res.data
            }
        })
    },
    methods: {
        register() {
            this.$bus.$emit("LoginFun","register")
        },
        login() {
            this.$bus.$emit("LoginFun")
        },
    }
}
</script>
<style scoped lang="scss">
@import "@/assets/css/var.scss";
.foot-line {
    background: url(@/assets/img/foot-line.png);
    background-position: center center;
    -webkit-background-size: auto 100%;
    background-size: auto 100%;
    color: #fff;
    ._wrap {
        height: 144px;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: space-between;
    }
    .l {
        font-size: 24px;
        font-style: italic;
    }
    .r {
        .btn {
            display: inline-block;
            line-height: 48px;
            text-align: center;
            width: 180px;
            border: 1px solid #fff;
            border-radius: 24px;
            +.btn {
                margin-left: 16px;
                background: #fff;
                color: $main_color;
            }
        }
    }
}

.footer {
    background: #0D1A2D;
    color: #fff;
    padding: 48px 0;
    ._wrap {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .item {
        border-left: 1px solid #26344A;
        flex-shrink: 0;
        >* {
            margin-left: -1px;
        }
        .title {
            height: 24px;
            line-height: 24px;
            border-left: 2px solid $main_color;
            padding: 0 14px;
            font-size: 16px;
            margin-bottom: 32px;
            .text2 {
                margin-left: 24px;
                font-size: 14px;
                color: #929CAF;
            }
        }
        .nav {
            border-left: 2px solid $main_color;
            padding: 0 14px;
            .n-item {
                +.n-item {
                    margin-top: 32px;
                }
            }
        }
        .contact {
            padding: 0 16px;
            .c-item {
                margin-bottom: 32px;
                span {
                    color: #929CAF;
                    margin-right: 16px;
                }
            }
        }
        .code {
            display: flex;
            flex-direction: row;
            padding: 0 14px;
            .cd-item {
                text-align: center;
                img {
                    width: 72px;
                    display: block;
                    margin-top: 16px;
                }
                +.cd-item {
                    margin-left: 35px;
                }
            }
        }
    }
}
</style>