import axios from 'axios';
import Qs from 'qs';
import Cookies from 'js-cookie';

let instance = axios.create({
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded'
    },
});
instance.interceptors.request.use(function(config) {
    Object.assign(config.data, {
        // token: Cookies.get('Token') || '',
        // HeaderKey: Cookies.get('HeaderKey') || localStorage.HeaderKey || ''
    })
    config.data = Qs.stringify(config.data)
    return config;
})
export default instance